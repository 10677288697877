<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="pb logo"
          class="logo"
        />
        <h2 class="brand-text text-primary ml-1">
          ProcessBucket
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to ProcessBucket! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- login form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <div v-if="isLoading === true">
                <b-spinner />
              </div>
              <!-- Form Step 1 -->

              <div v-if="step === 1">
                <!-- Organization -->
                <b-form-group
                  label="Organization"
                  label-for="organization-id"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Organization"
                    vid="organization"
                    rules="required"
                  >
                    <b-form-input
                      id="organization-id"
                      v-model="tenantId"
                      :state="getValidationState(validationContext)"
                      name="organization-id"
                      placeholder="Organization"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <!-- next button -->
              </div>

              <!-- Form Step 2 -->
              <!-- email -->
              <b-form-group
                v-if="step === 1"
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    spellcheck="false"
                    :state="getValidationState(validationContext)"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group v-if="step === 1">
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="validationContext"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="getValidationState(validationContext)"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group v-if="step === 1">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                v-if="step === 1"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social buttons -->
          <div class="d-flex row justify-content-center">
            <button
              type="button"
              class="login-with-google-btn"
              :disabled="tenantId.length === 0"
              @click="googleLogin"
            >
              Sign in with Google
            </button>
            <button
              :disabled="tenantId.length === 0"
              type="button"
              class="login-with-microsoft-btn"
              :style="{ backgroundImage: 'url(' + require('@/assets/images/logo/microsoft.svg') + ')' }"
              @click="microsoftLogin"
            >
              <!-- <b-img
                :src="MicrosoftIcon"
                alt="logo"
                class="logo"
              /> -->
              Sign in with Microsoft
            </button>
          </div>
        </b-col>
      </b-col>
      <!-- /login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import firebase from 'firebase'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  // BInputGroupAppend,
  // BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState, mapActions } from 'vuex'
import { $themeConfig } from '@themeConfig'
import store from '@/store'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    // BInputGroupAppend,
    // BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      tenantId: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      step: 1,
      // validation rules
      required,
      email,
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    const { GoogleIcon, MicrosoftIcon } = $themeConfig.icons
    return {
      appLogoImage,
      GoogleIcon,
      MicrosoftIcon,
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('app', ['isLoading']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    currentUser(newValue) {
      if (newValue) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Welcome ${newValue.displayName}`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text:
              'You have successfully logged in. Now you can start to explore!',
          },
        })
        this.updateSettings({ lastSeenOnline: Date() })
        this.$router.push('/')
      }
    },
  },
  created() {
    if (this.$route.params.tenant) {
      // this.step = 2
      this.tenantId = this.$route.params.tenant
    }
  },
  methods: {
    ...mapActions('setting', [
      'updateSettings',
    ]),
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          firebase.auth().tenantId = this.tenantId
          firebase
            .auth()
            .signInWithEmailAndPassword(this.userEmail, this.password)
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    enterTenantWarn() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'CoffeeIcon',
          variant: 'danger',
          text:
            'please enter Organization first',
        },
      })
    },
    googleLogin() {
      if (!(this.tenantId.length > 0)) {
        this.enterTenantWarn()
      } else {
        const provider = new firebase.auth.GoogleAuthProvider()
        firebase.auth().tenantId = this.tenantId
        firebase.auth().signInWithPopup(provider).catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text:
                `${err.code}:${err.message}`,
            },
          })
        })
      }
    },
    microsoftLogin() {
      if (!(this.tenantId.length > 0)) {
        this.enterTenantWarn()
      } else {
        firebase.auth().tenantId = this.tenantId
        firebase.auth().signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'))
      }
    },
    nextStep() {
      this.step = 2
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style lang="scss">
@import '../@core/scss/vue/pages/page-auth';

.logo {
  width: 20px;
  height: 15px;
  box-shadow: 0px;
  align-self: flex-start;
}

.my-buttons .active {
  color: #fff !important;
  background-color: #4285F4 !important;

  .btn {
    background-color: #4285F4 !important;
  }

  .btn-secondary {
    background-color: #4285F4 !important;
  }

  // border-color: #28a745 !important;
}

.google-btn {
  background-color: white !important;
  border-style: none;
  color: black;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  font-size: 15px;
  font-weight: bold;
}

.login-with-google-btn {
  width: 100%;
  transition: background-color .3s, box-shadow .3s;
  margin-bottom: 20px;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 10% 50%;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}

.login-with-microsoft-btn {
  @extend .login-with-google-btn;
  background-size: 20px;
}
</style>
